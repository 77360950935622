.app {
  height: 100%;
  width: 100%;
}

.header {
  background-color: #363636;
  display: flex;
  justify-content: center;
  height: 200px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.title-spacer {
  flex: 1px 1 1;
}

.title {
  color: white;
  font-size: 48px;
}

.subtitle {
  color: white;
  font-size: 24px;
}

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.searchbox {
  background-color: #FFFFFF;
  padding: 2px;
  margin-top: -25px;
}

.main-select {
  font-size: 16px;
  height: 40px;
}

.main-submit {
  background-color: #FF9900;
  border: 0px;
  color: #FFFFFF;
  font-size: 16px;
  height: 40px;
  width: 80px;
}

.main-submit:hover {
  background-color: white;
  color: black;
}

.search-results {

}